@import "../utilities/variables";

.invoice {
  width: 100%;
}

.summarySection {
  padding: 1rem 1.5rem;
  width: 100%;
}

.card {
  background-color: $mono-lighter;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.cardSection {
  width: 100%;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.lineItem {
  padding-left: 0.5rem;
  margin-bottom: 0.25rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
}

.condenseSections {
  padding: 0;
}

.excludeHeaders {
  display: flex;
  align-items: center;
  justify-content: center;
}

.additionalFees {
  color: $mono-darker;
}
